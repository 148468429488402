/* eslint-disable */
import { createReducer } from "@reduxjs/toolkit";
import {
	clearUserData,
	setUserData,
	setObfinished,
	updateNotification,
	updateConversations,
} from "../actions/user";

const initialState = {
	id: "",
	email: "",
	password: "",
	firstname: "",
	lastname: "",
	photo: "",
	phone: "",
	timezone: "",
	role: "",
	isSuperUser: false,
	isReader: false,
	status: "",
	company: null,
	companyname: null,
	title: "",
	notifications: [],
	progress: {
		progress: 0,
	},
	permissions: {},
	conversations: [],
};

const userReducer = createReducer(initialState, {
	[clearUserData + ""]: (state) => {
		state = {
			...state,
			...initialState,
		};
	},
	[setUserData + ""]: (state, action) => {
		const { payload } = action;
		state.id = payload.id;
		state.email = payload.email;
		state.firstname = payload.firstname;
		state.lastname = payload.lastname;
		state.phone = payload.phone;
		state.photo = payload.photo;
		state.timezone = payload.timezone;
		state.isSuperUser = payload.isSuperUser;
		state.isReader = payload.isReader;
		state.role = payload.role;
		state.status = payload.status;
		state.companyname = payload.companyname;
		state.company = payload.company;
		state.progress = payload.progress;
		state.title = payload.title;
		state.notifications = payload.notifications;
		state.permissions = payload.permissions;
		state.conversations = payload.conversations;
	},
	[setObfinished + ""]: (s, a) => {
		s.progress.progress = 1;
	},
	[updateNotification + ""]: (s, a) => {
		const { payload }: any = a;

		s.notifications = payload;
	},
	[updateConversations + ""]: (s, a) => {
		const { payload }: any = a;

		s.conversations = payload;
	},
});
export default userReducer;
