import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import OnboardingComplete from "./OnboardingComplete";
import Signup from "./Signup";
import Welcome from "./Welcome";
import "../Auth/index.scss";

const Onboarding = () => {
	const { url } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={url}>
				<Redirect exact push to={`${url}/welcome`} />
			</Route>
			<Route exact path={`${url}/welcome`}>
				<Welcome />
			</Route>
			<Route exact path={`${url}/onboarding-complete`}>
				<OnboardingComplete />
			</Route>
			<Route path={`${url}/signup/:id`}>
				<Signup />
			</Route>
			<Route exact path="*">
				<Redirect exact push to={`/error/404`} />
			</Route>
		</Switch>
	);
};

export default Onboarding;
