export const passwordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const pad = (num: number) => (num < 10 ? `0${num}` : `${num}`);

export const convertMSToHHMMSS = (timeInMS: number) => {
  let seconds = timeInMS / 1000;
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export const JSONtoCSV = (items: any[]) => {
  const replacer = (key: string, value: any) => (value === null ? "" : value);
  const header = Object.keys(items[0]);
  const csv = [
    header.join(","), // header row first
    ...items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join("\r\n");
  return csv;
};

export const downloadFile = (data: any, options?: any) => {
  const defaultOptions = {
    fileName: "export",
    fileFormat: "csv",
    blobType: "application/octetstream",
  };
  const opts = { ...defaultOptions, ...options };
  const blob = new Blob([data], {
    type: opts.blobType,
  });
  const objUrl = window.URL.createObjectURL(blob);
  let anchor = document.createElement("a");
  anchor.download = `${opts.fileName}.${opts.fileFormat}`;
  anchor.href = objUrl;
  anchor.click();
};

export const createDate = (
  years: number = 0,
  months: number = 0,
  days: number = 0,
  hours: number = 0,
  minutes: number = 0,
  seconds: number = 0,
  ms: number = 0
) => {
  let date = new Date();
  ms && date.setMilliseconds(date.getMilliseconds() + ms);
  seconds && date.setSeconds(date.getSeconds() + seconds);
  minutes && date.setMinutes(date.getMinutes() + minutes);
  hours && date.setHours(date.getHours() + hours);
  days && date.setDate(date.getDate() + days);
  months && date.setMonth(date.getMonth() + months);
  years && date.setFullYear(date.getFullYear() + years);
  return date;
};
