export default {
  auth: '/auth',
  onboarding: '/onboarding',
  contactorOnboarding: '/contractorOnboarding',
  employeeOnboarding: '/employeeOnboarding/:uid',
  admin: '/admin',
  contractor: '/contractor',
  esign: '/signature/:code',
  error: '/error/:code',
};
