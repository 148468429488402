import React from "react";
import SubClarityLogo from 'assets/images/logo/subclarity-logo.svg';
import success from "assets/images/success.png";

const OnboardingComplete = () => {
	return (
		<div className="loginContainer">
			<div className="authCard">
			<div className="logoAreaOnboarding">
				<img src={SubClarityLogo} alt="Welcome to SubClarity" />
				<hr className="fullDivider" />
			</div>
				<div className="welcomeImage">
					<img src={success} alt="Created successfully" />
				</div>
				<h1 className="welcomeMessage">Account Created Successfully!</h1>
				<p className="welcomeDescription mb-5">
					Logging you in to the platform...
				</p>
			</div>
		</div>
	);
};

export default OnboardingComplete;
