import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
// import styles from './index.scss';

const Offline = () => {
	React.useEffect(() => {
		window.addEventListener("online", updateOnlineStatus);
		window.addEventListener("offline", updateOnlineStatus);

		return () => {
			window.removeEventListener("online", updateOnlineStatus);
			window.removeEventListener("offline", updateOnlineStatus);
		};
	});

	const updateOnlineStatus = () => {
		if (navigator.onLine) {
			setOffline(false);
		} else {
			setOffline(true);
		}
	};
	const dispatch = useDispatch();
	const offline = useSelector((s: any) => s.global.offline);
	const setOffline = (s: any) => {
		dispatch({
			type: "SET_OFFLINE",
			payload: { status: s },
		});
	};

	return (
		<>
			{offline && (
				<>
					<div
						style={{
							width: `100%`,
							padding: `0 64px`,
							height: `64px`,
							display: "flex",
							background: `#222`,
							zIndex: 90,
							cursor: `default`,
							textShadow: `0 1px rgba(0,0,0,0.15)`,
							alignItems: `center`,
							justifyContent: `center`,
							position: `fixed`,
						}}
					>
						<p
							style={{
								fontWeight: 700,
								fontSize: `1rem`,
								color: `#fff`,
								padding: `0 10px`,
								marginRight: `1em`,
							}}
						>
							Your device lost its internet connection. Please check your
							Internet connection
						</p>
						<Button
							style={{
								margin: `auto 2em`,
							}}
							onClick={() => {
								window.location.reload();
							}}
						>
							RELOAD
						</Button>
					</div>
					<div style={{ height: `64px` }}></div>
				</>
			)}
		</>
	);
};

export default Offline;
