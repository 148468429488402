import React from "react";
import { Button } from "antd";
import SubClarityLogo from 'assets/images/logo/subclarity-logo.svg';
import handShake from "assets/images/handshake.png";
import "./index.scss";
import { Link } from "react-router-dom";

const Welcome = ({ changeState }: any) => {
	return (
		<div className="loginContainer">
			<div className="authCard">
				<div className="logoAreaOnboarding">
					<img src={SubClarityLogo} alt="Welcome to SubClarity" />
					<hr className="fullDivider" />
				</div>
				<h1 className="welcomeMessage">
					Welcome to <strong>Sub Clarity</strong>
				</h1>
				{/* <p className="welcomeDescription">
					Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
					sint. Velit officia consequat duis enim velit mollit.{" "}
				</p> */}
				<div className="buttons">
					<Button size="large" type="primary" onClick={() => changeState()}>
						Let’s Get Started
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Welcome;
