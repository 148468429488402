import React from "react";
import { PasswordInput } from "antd-password-input-strength";

const PasswordStrength = ({ inputProps, onChange }: any) => {
	return (
		<PasswordInput
			inputProps={inputProps}
			settings={{
				colorScheme: {
					levels: ["#ff4033", "#fe940d", "#ffd908", "#cbe11d", "#6ecc3a"],
					noLevel: "lightgrey",
				},
				height: 5,
				alwaysVisible: false,
			}}
			onChange={onChange}
		/>
	);
};

export default PasswordStrength;
