import { store } from '../store';
import { getUserDetails, getProgress, logoutUser } from '../apis';
import { getAllMessagesById } from '../apis/messaging';
// import { push } from 'connected-react-router';
import { createAction } from '@reduxjs/toolkit';
import { message } from 'antd';

export const setUserData = createAction('SET_USER_DETAILS', (data) => ({
  payload: data,
}));

export const clearUserData = createAction('CLEAR_USER_DATA');
export const setObfinished = createAction('SET_ONBOARDING_COMPLETE');

export const getUserAction = () => (dispatch: any) => {
  Promise.all([getUserDetails(), getProgress()]).then((data: any) => {
    dispatch(
      setUserData({
        ...data[0].data,
        progress: data[1].data,
      })
    );
  }).catch((e)=> {
    message.error("Failed to fetch user information and company progress.");
    logoutUser();
  });
};

export const updateConversationAction = (conversationtoUpdate: any) => (dispatch: any) => {
  let conversations = store.getState().user.conversations;
  let conversationArray: any = [...conversations];
  getAllMessagesById(conversationtoUpdate.id).then((payload: any) => {
    conversationArray.unshift(payload.data);
    dispatch(updateConversations(conversationArray));
  }); 
};

export const updateMessageAction = (conversationId: any) => (dispatch: any) => {
  let conversations = store.getState().user.conversations;
  let currentUserId = store.getState().user.id;
  let getMessages = false;
  if(conversations.length > 0) {
    let currentConvo = conversations.filter((conversation: any) => conversation.id === conversationId);
    if(currentConvo.length > 0) {
      currentConvo[0].users.map((user: any) => {
        if(user.id === currentUserId) {
          getMessages = true;
        }
      });
    }
  }
  if(getMessages) {
    getAllMessagesById(conversationId).then((payload: any) => {
      let conversationArray: any = [];
      conversations.map((conversation: any) => {
        let conObj: any = {};
        if(conversation.id === conversationId) {
          conObj = payload.data;
        } else {
          conObj = conversation;
        }
        conversationArray.push(conObj);
        }); 
      dispatch(updateConversations(conversationArray)); 
    });
  }
};

export const updateNotification = createAction(
  'FETCH_NOTIFICATIONS',
  (data) => ({
    payload: data,
  })
);

export const updateConversations = createAction(
  'UPDATE_CONVERSATIONS',
  (data) => ({
    payload: data,
  })
);
