import React from 'react';
import socket from './socket/socketConfig';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Spin, message } from 'antd';
import Offline from './components/offline';
import { getCookie } from './utils/cookies';
import { constants } from './utils/constants';
import ErrorPages from './components/ErrorPages';
import { getUserAction } from './actions/user';
import routes from './routePaths';
import './App.scss';
import Onboarding from 'modules/Onboarding';
import { logoutUser } from './apis';

const Auth = React.lazy(() => import('./modules/Auth'));
const Opening = React.lazy(() => import('./modules/Opening'));
const Admin = React.lazy(() => import('./modules/Admin'));
const Contractor = React.lazy(() => import('./modules/Contractor'));
const ContractorOnboarding = React.lazy(
  () => import('./modules/contractorOnboarding')
);
const EmployeeOb = React.lazy(
  () => import('./modules/contractorOnboarding/employeeOb')
);

const EsignHandler = React.lazy(() => import('./components/Esign'));

function App() {
  const loading = useSelector((s: any) => s.global.loader) > 0;
  const dispatch = useDispatch();

  const handleWindowSizeChange = () => {
    dispatch({ type: 'SET_IS_MOBILE', payload: window.innerWidth });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();

    let elm: any = document.querySelector('#root');

    setTimeout(() => {
      elm.classList.remove('loadBlur');
    }, 600);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && (
        <div className='loadBg'>
          <Spin className='centerElm' size='large' />
        </div>
      )}
      <Offline />
      <React.Suspense
        fallback={
          <div className='loadBg'>
            <Spin className='centerElm' size='large' />
          </div>
        }
      >
        <Switch>
          <Route path={routes.auth}>
            <Auth />
          </Route>
          <Route path={routes.esign}>
            <EsignHandler />
          </Route>
          <Route path={routes.onboarding}>
            <Onboarding />
          </Route>
          <Route path='/' exact>
            {OpenRedirect()}
          </Route>
          <RouteWrapper path={routes.contactorOnboarding}>
            <ContractorOnboarding />
          </RouteWrapper>
          <RouteWrapper path='/start'>
            {HomeRedirect()}
          </RouteWrapper>
          <RouteWrapper path={routes.admin}>
            <Admin />
          </RouteWrapper>
          <RouteWrapper path={routes.contractor}>
            <Contractor />
          </RouteWrapper>
          <RouteWrapper path={routes.employeeOnboarding}>
            <EmployeeOb />
          </RouteWrapper>
          <Route path={routes.error}>
            <ErrorPages />
          </Route>
          <Route path='*'>
            <ErrorPages code='404' />
          </Route>
        </Switch>
      </React.Suspense>
    </>
  );
}

const RouteWrapper = (props: any) => {
  const dispatch = useDispatch();
  const isLoggedIn = !!getCookie(`sc-auth-token.${constants('env')}`);
  const fetchedUserData = useSelector((s: any) => s.user.id) !== '';
  // todo RBAC and authentication

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return isLoggedIn ? (
    fetchedUserData ? (
      <Route {...props} />
    ) : (
      <Spin className='centerElm' size='large' />
    )
  ) : (
    <Redirect to={routes.auth} />
  );
};

const OpenRedirect = () => {
  const isLoggedIn = !!getCookie(`sc-auth-token.${constants('env')}`);

  return isLoggedIn ? <Redirect to='/start' /> : <Opening />
}

const HomeRedirect = () => {
  const user = useSelector((s: any) => s.user);
  React.useEffect(() => {
    socket.on('connect_error', (err: any) => {
      console.log('connect failed' + err);
    });
    socket.on('connect', () => {
      console.log('socketId: ', socket.id);
    });
    return () => socket.disconnect();
  }, []);
  if (user.id) {
    socket.on(`logout-${user.id}`, () => {
      message.warning("User already logged in on another instance. Please be notified that you will be logged out from this browser.");
      setTimeout(() => logoutUser(), 5000);
    });
  }
  if (user.role === 'ADMIN') {
    return <Redirect to={routes.admin} />;
  } else {
    return <Redirect to={routes.contractor} />;
  }
};

export default App;
