
const setCookie = (obj: any): any => {
  Object.keys(obj).forEach((key) => {
    document.cookie = `${key}=${obj[key]}; path=/;`;
  });
};

const getCookie = (name: any) => {
  const value = `; ${document.cookie}`;
  let parts = [];
  parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts?.pop()?.split(';').shift();
  }
  return '';
};

const deleteCookie = (keys: any): any => {
  keys.forEach((k: any) => {
    document.cookie = `${k}=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  });
};

export { setCookie, getCookie, deleteCookie };
