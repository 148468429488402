import React from 'react';
import { history } from '../store';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      errorInfo: null,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    });
    console.error('Error with EP Platform Client', {
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      history.push('/error/500');
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
