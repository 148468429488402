import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './index.scss';
import { Button, Result } from 'antd';

const ErrorComponent = (props: any) => {
  const { codeProp } = props;
  let { code }: any = useParams();
  const history = useHistory();

  if (codeProp) {
    code = codeProp;
  } else if (!code) {
    code = '404';
  }

  const getMessage = (): any => {
    if (code === '404') {
      return `The Page you are looking for does not exist.`;
    } else if (code === '403') {
      return 'Module is blocked';
    } else if (parseInt(code, 10) >= 500) {
      return 'Your Session has been timed out.';
    } else {
      return 'There some problem with your Internet Connection. Please Check and try again.';
    }
  };

  return (
    <div className='centerAlign'>
      <Result
        status={code}
        title={code}
        subTitle={getMessage()}
        extra={
          <Button
            type='primary'
            size='large'
            onClick={() => {
              history.push('/');
            }}
          >
            Click here to Refresh
          </Button>
        }
      />
    </div>
  );
};

export default ErrorComponent;
