import { Button, Input, Checkbox, message } from "antd";
import { getUserUnauth, loginUser, resetPassword } from "apis";
import SubClarityLogo from 'assets/images/logo/subclarity-logo.svg';
import PasswordStrength from "components/PasswordStrength";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { passwordRegex } from "utils/helpers";
import * as Yup from "yup";
import OnboardingComplete from "../OnboardingComplete";
import Welcome from "../Welcome";

const resetPasswordSchema = Yup.object().shape({
	firstname: Yup.string().required("First name is Required"),
	lastname: Yup.string().required("Last name is Required"),
	companyname: Yup.string().required("Company name is Required"),
	password: Yup.string()
		.matches(
			passwordRegex,
			"Password must contain at least one uppercase character, one lowercase character, one number and one special character and must be at least 8 characters long."
		)
		.required("Password is Required")
		.min(7),
	confirmPassword: Yup.string()
		.required("Passwords must match")
		.oneOf([Yup.ref("password")], "Passwords must match"),
	terms: Yup.bool()
		.required("Please accept our terms and policy")
		.oneOf([true], "Please accept our terms and policy"),
});

type Params = {
	id: string;
};

const Signup = () => {
	const params: Params = useParams();
	const [signupState, setSignupState] = useState("welcome");
	const [user, setUser] = useState<any>({});
	const formik = useFormik({
		initialValues: {
			firstname: user.firstname || "",
			lastname: user.lastname || "",
			companyname: user.companyname || "Subclarity",
			password: "",
			confirmPassword: "",
			terms: false,
		},
		validationSchema: resetPasswordSchema,
		onSubmit: (values) => submit(values),
		enableReinitialize: true,
	});

	const history = useHistory();

	const submit = async (formDetails: any) => {
		let body = { ...formDetails };
		delete body.confirmPassword;
		delete body.terms;
		body.id = user.id;
		body.password = `${btoa(new Date().toUTCString())}\\${btoa(
			user.code
		)}\\${btoa(formDetails.password)}`;
		resetPassword(body)
			.then(() => {
				setSignupState("thankyou");
				loginUser({ email: user.email, password: formDetails.password }, () => {
					setTimeout(() => {
						history.push("/start");
					}, 1000);
				});
			})
			.catch(() => {
				message.error("Unable to perform this operation. Please try again.");
			});
	};

	useEffect(() => {
		if (params.id) {
			getUser();
		}
	}, [params.id]);

	const getUser = () => {
		getUserUnauth(params.id)
			.then(({ data }) => {
				console.log(data);
				setUser(data);
			})
			.catch(() =>
				// message.error(
				// 	"User seems to be active. Please check with the administrator."
				// )
				history.push("/auth/login")
			);
	};

	return (
		<>
			{signupState === "welcome" ? (
				<Welcome changeState={() => setSignupState("signup")} />
			) : signupState === "signup" ? (
				<div className="loginContainer">
					<div className="authCard">
					<div className="logoAreaOnboarding">
						<img src={SubClarityLogo} alt="Welcome to SubClarity" />
						<hr className="fullDivider" />
					</div>
						<form className="loginForm" onSubmit={formik.handleSubmit}>
							<div className="ipContainer">
								<label htmlFor="firstname">First Name</label>
								<Input
									placeholder="Eg. John"
									onChange={(event) => formik.handleChange(event)}
									onBlur={(event) => formik.handleBlur(event)}
									value={formik.values.firstname}
									name="firstname"
								/>
								{formik.errors.firstname && formik.touched.firstname && (
									<span className="formErr">{formik.errors.firstname}</span>
								)}
							</div>
							<div className="ipContainer">
								<label htmlFor="lastname">Last Name</label>
								<Input
									placeholder="Eg. Appleseed "
									onChange={(event) => formik.handleChange(event)}
									onBlur={(event) => formik.handleBlur(event)}
									value={formik.values.lastname}
									name="lastname"
								/>
								{formik.errors.lastname && formik.touched.lastname && (
									<span className="formErr">{formik.errors.lastname}</span>
								)}
							</div>
							{user.role !== "ADMIN" && (
								<div className="ipContainer">
									<label htmlFor="companyname">Company Name</label>
									<Input
										placeholder="Enter Company Name"
										onChange={(event) => formik.handleChange(event)}
										onBlur={(event) => formik.handleBlur(event)}
										value={formik.values.companyname}
										name="companyname"
										disabled={formik.values.companyname}
									/>
									{formik.errors.companyname && formik.touched.companyname && (
										<span className="formErr">{formik.errors.companyname}</span>
									)}
								</div>
							)}
							<div className="ipContainer">
								<label htmlFor="password">Create Password</label>
								<PasswordStrength
									inputProps={{
										placeholder: "Enter Password",
										onBlur: (event: any) => formik.handleBlur(event),
										value: formik.values.password,
										name: "password",
										className: "strength-indicator",
									}}
									onChange={(event: any) => formik.handleChange(event)}
								/>
								{/* <Input.Password
									placeholder="Enter Password"
									onChange={(event) => formik.handleChange(event)}
									onBlur={(event) => formik.handleBlur(event)}
									value={formik.values.password}
									name="password"
								/> */}
								{formik.errors.password && formik.touched.password && (
									<span className="formErr">{formik.errors.password}</span>
								)}
							</div>
							<div className="ipContainer">
								<label htmlFor="confirmPassword">Re-enter Password</label>
								<Input.Password
									className="padded"
									placeholder="Re-enter Password"
									onChange={(event) => formik.handleChange(event)}
									onBlur={(event) => formik.handleBlur(event)}
									value={formik.values.confirmPassword}
									visibilityToggle={false}
									name="confirmPassword"
									autoComplete="new-password"
								/>
								{formik.errors.confirmPassword &&
									formik.touched.confirmPassword && (
										<span className="formErr">
											{formik.errors.confirmPassword}
										</span>
									)}
							</div>
							<div className="ipContainer">
								<Checkbox
									className="termsConditions"
									name="terms"
									onChange={formik.handleChange}
								>
									I accept the VMS{" "}
									<span className="hyperlink">Terms & Conditions</span> and{" "}
									<span className="hyperlink">Privacy Policy</span>
								</Checkbox>
							</div>
							<div className="buttons">
								<Button
									size="large"
									htmlType="submit"
									type="primary"
									disabled={!formik.isValid || !formik.dirty}
								>
									Create Account
								</Button>
							</div>
						</form>
					</div>
				</div>
			) : (
				signupState === "thankyou" && <OnboardingComplete />
			)}
		</>
	);
};

export default Signup;
