import axios from "axios";
import { store } from "../store";
import { getCookie, deleteCookie, setCookie } from "../utils/cookies";
import { push } from "connected-react-router";
import { constants } from "../utils/constants";

/*
TODO Write a network layer before axios,
Handle cancellation of network call or ignoring stale response
Camelize all api keys at deep level
*/

export const api = axios.create({
	baseURL: constants("serverUrl"),
	timeout: 0,
});

api.interceptors.request.use((config) => {
	const newConfig = { ...config };
	newConfig.headers[`Authorization`] = `Bearer ${getCookie(
		`sc-auth-token.${constants("env")}`
	)}`;
	return newConfig;
});

export default api;

api.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = error.response?.status;
		if (status === 401) {
			deleteCookie([`sc-auth-token.${constants("env")}`]);
		} else if (status > 422) {
			// store.dispatch(push(`/error/${error.response.status}`));
			console.log(error);
		} else if (!status) {
			// store.dispatch(push(`/error/500`));
			console.log(error);
		}
		return Promise.reject(error);
	}
);

export function loginUser({ email, password }: any, cb?: Function) {
	const url = `/auth/login`;
	return api
		.post(url, {
			email: email,
			password: `${btoa(new Date().toUTCString())}\\${btoa(password)}`,
		})
		.then(({ data }: any) => {
			const tokenKey = `sc-auth-token.${constants("env")}`;
			setCookie({
				[tokenKey]: data.accessToken,
			});

			if (cb) {
				cb(data);
			} else {
				store.dispatch(push(`/start`));
				savelog();
			}
		})
		.catch((err) => {
			return Promise.reject(err);
		});
}

export function savelog() {
	const url = `/logs/save`;
	return api.post(url);
}

export function resetPassword(formDetails: any) {
	const url = `/auth/reset`;
	return api.post(url, formDetails);
}

export function changePassword(formDetails: any) {
	const url = `/auth/change`;
	return api.post(url, formDetails);
}

export function changeContractorPassword(formDetails: any) {
	const url = `/auth/changeContractorPassword`;
	return api.post(url, formDetails);
}

export function forgotPassword({ email }: any) {
	const url = `/auth/forgot`;
	return api.post(url, { email });
}

export function uploadProfilePic(file: any) {
	const url = `/users/uploadPic`;
	var bodyFormData = new FormData();
	bodyFormData.append("file", file);
	return api.post(url, bodyFormData);
}

export function uploadCompanyLogo(file: any) {
	const url = `/companies/uploadPic`;
	var bodyFormData = new FormData();
	bodyFormData.append("file", file);
	return api.post(url, bodyFormData);
}

export function getUserDetails() {
	const url = `/users/current?relations=company,company.employees,company.employees.invoices,company.spoc,conversations,conversations.users,company.team`;
	return api.get(url);
}

export function updateUserDetails(id: string, body: any) {
	const url = `/users/${id}`;
	return api.put(url, body);
}

export function getUserDetailsWithId(id: string) {
	const url = `/users/${id}`;
	return api.get(url);
}

export function inviteUser(body: any) {
	const url = `/users`;
	return api.post(url, body);
}

export function createDemoData(userid: any) {
	if (constants('env') === 'demo') {
		const url = `/demo/${userid}`;
		return api.post(url);
	}
}

export function getProgress() {
	const url = `/progress/current`;
	return api.get(url);
}

export function getProgressOfCompany(id: string) {
	const url = `/progress/${id}`;
	return api.get(url);
}

export function logoutUser() {
	deleteCookie([`sc-auth-token.${constants("env")}`]);
	push("/auth/login");
	window.location.reload();
}

export function clearSessions() {
	const url = `users/clearActiveSessions`;
	return api.post(url);
}

export function getUserUnauth(id: string) {
	const url = `/auth/${id}`;
	return api.get(url);
}

export function fileUpload(file: any, location?: any) {
	const url = `/files`;
	const bodyFormData = new FormData();
	bodyFormData.append("file", file);
	bodyFormData.append("location", location);
	return api.post(url, bodyFormData);
}

export const googlePlaces = (str: string) =>
	api.get(`/utils/places/autocomplete?input=${str}`);

export function inviteCompanyTeamMember(body: any) {
	const url = `/companies`;
	return api.put(url, body);
}