import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { reduxBatch } from "@manaflair/redux-batch";
import globalReducer from "./index.reducer";
import userReducer from "../reducers/user";

const history = createBrowserHistory();
history.listen(() => {
	window.scrollTo(0, 0);
});

const router: any = connectRouter(history);

const store: any = configureStore({
	reducer: {
		router,
		global: globalReducer,
		user: userReducer
	},
	middleware: [thunk, routerMiddleware(history)],
	devTools: process.env.NODE_ENV !== "production",
	enhancers: [reduxBatch],
});

export { store, history };
export type RootState = ReturnType<typeof store.getState>;
