import { api } from "./index";

export function getAllUsers() {
	const url = `/conversations/users`;
	return api.get(url);
}

export function getContractors() {
	const url = `/conversations/contractors`;
	return api.get(url);
}

export function getAllMessagesById(id: string) {
	const url = `/conversations/${id}`;
	return api.get(url);
}

export function saveMessage(body: any) {
	const url = `/messages`;
	return api.put(url, body);
}

export function startConversation(body: any) {
	const url = `/conversations`;
	return api.put(url, body);
}

export function startGroupConversation(body: any) {
	const url = `/conversations/group`;
	return api.put(url, body);
}

export function startGroupConversationWithContractors(body: any) {
	const url = `/conversations/group/contractors`;
	return api.put(url, body);
}
